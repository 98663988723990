import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://www.voicesummit.ai/"
      }}>{`VOICE Summit`}</a>{` hosted nearly 2,500 voice-first enthusiasts and disruptors last week at Newark’s New Jersey Institute of Technology. The excitement for voice technology was brimming in every discussion as brands and developers shared their experiences building conversational products.`}</p>
    <p>{`I had the honor of participating in three speaking opportunities at VOICE: Leveraging Analytics to Build Better Bots (Solo), The Art of Conversational Agents (Panel), and Leaders in Voice (Panel). This recap reflects the three major themes of questions asked during these sessions and the conference overall.`}</p>
    <p>{`Pictured left to right: Leaders in VOICE panel moderated by Bradley Metrock, Cathy Pearl, Chiori Hori, Brooke Hawkins, Justina Nguyen, Theodora Lau, Heidi Culbertson`}</p>
    <h3><strong parentName="h3">{`What skills are required for building great conversational experiences?`}</strong></h3>
    <p>{`Most people assume programming is the most important skill needed to build voice applications, but the technology is only a small part of the challenge here. At the end of the day, you’re creating a conversation and you need people who know how to do just that. Whether it’s a playwright or a comedian, an incredibly valued and critical role is a conversation designer.`}</p>
    <p>{`Cathy Pearl, Head of Conversational Design Outreach at Google, noted how important sample scripting is to the process of creating a conversational experience. People consume and communicate information differently depending on how its delivered. Writing and acting out a sample script simply isn’t enough. When designing a conversational experience with a voice assistant, you need to respect the current capabilities of the interfaces and prioritize affordance in design.`}</p>
    <p>{`Conversation designers are not the only people needed at the table. Brooke Hawkins, Virtual Assistant Designer at Nuance Communications, requires a diverse set of voices to build a diverse conversational agent. A talented conversational agent is only as good as the data you feed it.`}</p>
    <p>{`Most importantly, Will Hall (CTO of RAIN Agency) reminded us that brands are being invited into and have access to a private, sacred space — a user’s home. Every speaker echoed this idea and emphasized how important it is to be empathetic and think about user behavior and use cases in relation to placement of the speaker.`}</p>
    <h3><strong parentName="h3">{`Will conversational agents replace humans?`}</strong></h3>
    <p>{`Another question that I was frequently asked at VOICE Summit was, “Do you think chatbots or voice assistants will replace humans? Or, affect the workforce negatively?”`}</p>
    <p>{`Conversational products will not replace humans. They serve as a resource for humans to do their jobs more efficiently. You need to leverage human knowledge and empathy to build a great bot. One of my favorite examples of this is Intuit’s approach to building Intuit’s Quickbook Assistant. Spencer Uttley, Intuit’s product manager leading the initiative for conversational products, says he implores customer service teams to contribute their indispensable expertise to help build this bot.`}</p>
    <p>{`Along the same vein, Sherri Hutter (Director at Strategic Innovation at Salesforce) includes how employees feel about their work performance and satisfaction as a primary KPI for measuring bot success. If their bot is able to address simple, incessant customer tickets, the team can then address more challenging customer issues.`}</p>
    <h3><strong parentName="h3">{`What are the best tools for measuring bot success and user satisfaction?`}</strong></h3>
    <p>{`It was clear that people are prioritizing end-user experience, but unclear of how to actually monitor engagement and usage. Builders want feedback and they want to better understand how users interact with their products. In `}{`*`}{`every talk`}{`*`}{` I participated in, I was asked what was the best way to measure user behavior.`}</p>
    <p>{`There are a few ways to `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/tour"
      }}>{`monitor user behavior with Dashbot`}</a>{`:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Exit Message Report`}</strong>{` identifies which message caused the most abandoned conversations`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.dashbot.io/2018/05/02/conversation-paths/"
        }}><strong parentName="a">{`Conversation Path`}</strong></a>{` shows which conversation flows have high engagement`}</li>
      <li parentName="ul"><strong parentName="li">{`Goal Funnels`}</strong>{` highlight what path has the highest conversions`}</li>
      <li parentName="ul"><strong parentName="li">{`Audience Builder`}</strong>{` segments users by conversational attributes`}</li>
    </ul>
    <p>{`More interestingly and frequently, speakers and attendees brought up a challenge they faced when trying to make the raw text useful. They described how tedious it was to manually tag similar messages and group intents in order to make their data useful for training conversational agents. Several of our customers reported having this issue as well.`}</p>
    <p>{`Enter `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/features/phrase-clustering/"
      }}>{`Dashbot Phrase Clustering`}</a>{`.`}</p>
    <p>{`Phrase Clustering enables builders to better understand users’ intents and behaviors to improve response effectiveness and extract data to train conversational agents. Builders can view similarly clustered raw messages and identify unhandled and mis-handled intents. This report can also be applied to human-to-human chat to improve customer service interactions.`}</p>
    <p>{`VOICE Summit was an amazing gathering of voice leaders. The conference was an indicator of how `}<a parentName="p" {...{
        "href": "https://venturebeat.com/2018/04/17/3-changes-that-indicate-an-industry-shift-in-conversational-ai/"
      }}>{`quickly the industry is moving`}</a>{` and who is really bullish about this space. A huge thank-you to Pete Erickson, Anita Santa Coloma, and the entire Modev team for hosting an incredible event and inviting Dashbot!`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to understand user behaviors, optimize response effectiveness, and increase user satisfaction through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and broadcast messaging for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales/?ref=blog+voice+summit+recap"
      }}><br parentName="a"></br>
        {`Contact us for a demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      